<!--
 * @Author: DY
 * @Date: 2023-01-06 17:09:28
 * @LastEditTime: 2023-09-25 15:04:55
 * @Description:
 * @FilePath: \BusinessPlatform\src\components\picturePreview\index.vue
-->
<template>
    <div class="picturePreview">
        <el-carousel :interval="5000" arrow="always" :autoplay="false">
            <el-carousel-item v-for="(item, index) in imgSrc" :key="index">
                <img v-if="isImg" :src="item" alt="">
                <iframe
                    v-else
                    :src="item"
                    width="100%"
                    height="100%"
                    frameborder="0"
                ></iframe>
            </el-carousel-item>
        </el-carousel>
    </div>
</template>

<script>
// import pdf from 'vue-pdf';
export default {
    // 图片预览
    name: 'picture-preview',
    components: {
        // pdf,
    },
    props: {
        code: {
            type: [Array, Object, String],
            required: false, // 是否必传
        },
        extr: {
            type: [Array, Object, String],
            required: false, // 是否必传
        },
    },
    data() {
        return {
            // 附件集合
            imgSrc: [],
            isImg: false,
        };
    },
    created() {
    },
    mounted() {
        let params = this.extr;
        this.isImg = params.isImg;
        if (this.code && Object.keys(this.code).length > 0) {
            params = this.code;
        }
        if (params.imgSrc.length > 0) {
            this.imgSrc = params.imgSrc;
        } else if (params.fileIds.length > 0) {
            this.getImgUrl(params.fileIds);
        }
    },
    methods: {
        // 获取文件预览地址
        async getImgUrl(fileIds = []) {
            this.isImg = true;
            const pms = [];
            fileIds.forEach(id => {
                const pm = this.getFileInfo(id);
                pms.push(pm);
            });
            const resFiles = await Promise.all(pms);
            this.imgSrc = resFiles.map(item => item.preview_url);
        },
        getFileInfo(fileId) {
            return this.$axios.get(this.FILE.FILE_BASE_URL_OSS() + 'api/GetFileInfo/' + fileId);
        },
    },

};
</script>
<style lang="stylus">
.picturePreview
    height 100%
    padding 0.14rem
    .el-carousel--horizontal, .el-carousel__container
        height 100% !important
        overflow hidden
        text-align: center;
</style>
